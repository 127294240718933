@charset "utf-8";

// Font specifications. I keep changing my mind on what are the most pleasant
// fonts to my eyes, so I won't bother commenting them here.'
$serif-font-family:
  ui-serif, "Roboto Serif", "Noto Serif", Tinos, serif, "Noto Emoji",
  "Noto Color Emoji", "Segoe UI Emoji", emoji;
$sans-serif-font-family:
  ui-sans-serif, "Roboto Flex", "Segoe UI Variable", Roboto, "Noto Sans", Arimo,
  sans-serif, "Noto Emoji", "Noto Color Emoji", "Segoe UI Emoji", emoji;
$monospace-font-family:
  ui-monospace, "Roboto Mono", "Segoe UI Mono", "Noto Mono", Cousine, monospace,
  "Noto Emoji", "Noto Color Emoji", "Segoe UI Emoji", emoji;
// Must be in the end under threat of undefined variable error.
$base-font-family: $sans-serif-font-family;

// CSS of used Jekyll theme
@import "minima";

// My overrides, often !important so they actually get used

// As high in css as can be edited.
:root {
  color-scheme: dark light !important;
}

// Revert to defaults, or make settings default
* {
  color: revert !important;
  overflow-wrap: break-word !important;
  /* Funny trick for multiple columned pages with automatic column count
  column-width: 78ch !important;
  */
  /* begin https://clagnut.com/blog/2395/ */
  hyphens: auto !important;
  hyphenate-limit-chars: 6 3 3 !important;
  hyphenate-limit-lines: 2 !important;
  hyphenate-limit-last: always !important;
  hyphenate-limit-zone: 8% !important;
  /* end https://clagnut.com/blog/2395/ */
  // Experimental trick to make all emojis text if supported.
  font-variant-emoji: text;
  /* WCAG minimum suggestions */
  margin-bottom: 2 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.12 !important;
  word-spacing: 0.16 !important;
}

// E.g. blesmrt.net vs bIesmrt.net
a {
  text-decoration: underline !important;
  font-family: $serif-font-family;
}

// Extraneous considering the above.
//a.site-title {
//  font-family: $serif-font-family;
//}

// .page-content {
//     width: 100% !important;
//     max-width: 78ch !important;
//     margin: 0 auto !important;
// }

// Monospace preferred for code
code,
pre {
  font-family: $monospace-font-family !important;
}

// So it will not look bigger than normal text
code {
  font-size: 0.8em !important;
}

// Serif for headings to constrast with everything else being sans-serif
// (except links).
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $serif-font-family !important;
}

// Overrides for light themes
@media (prefers-color-scheme: light) {
  *:not(a) {
    border-color: #000000 !important;
  }
  .ftf-dma-note {
    max-width: 78ch !important;
    margin: 0 auto !important;
  }
}

// Overrides for dark themes
@media (prefers-color-scheme: dark) {
  *:not(a) {
    color: #ffb700 !important;
    border-color: #ffb700 !important;
    background-color: #000000 !important;
  }

  // Adblocker detection)
  .ftf-dma-note {
    color-scheme: only dark !important;
    color: #ffb700 !important;
    border-color: #ffb700 !important;
    background-color: #000000 !important;
    max-width: 78ch !important;
    margin: 0 auto !important;
  }
}

// This is used in the index
#avatar {
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  border: 1px solid;
  //display: block;
  display: float;
  float: right;
}

// This is the short bio in the index and all my profiles
#bio {
  //text-align: center;
  font-style: italic;
  font-family: ui-cursive, $serif-font-family;
}

// _layouts/mini.html calls this for returning to index!
.centered {
  list-style-type: none;
  margin: 0 auto;
  text-align: center;
}

// At least used in the index SSH key fingerprints
.monospaced {
  font-family: $monospace-font-family;
  font-size: 0.8em;
}
